import { render, staticRenderFns } from "./HeroSection.vue?vue&type=template&id=0498ee1e&scoped=true"
var script = {}
import style0 from "./HeroSection.vue?vue&type=style&index=0&id=0498ee1e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0498ee1e",
  null
  
)

export default component.exports